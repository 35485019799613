export enum Screens {
  BOOKING_DATE = 1,
  BOOKING_TIME = 2,
  BOOKING_GUEST_INFO = 3,
  BOOKING_OTP = 4,
  BOOKING_CONFIRMATION = 5,
  BOOKING_STATUS = 6,
}
// export enum Screens {
//   BOOKING_FORM = 0,
//   BOOKING_GUEST_INFO = 1,
//   BOOKING_OTP = 2,
//   BOOKING_CONFIRMATION = 3,
//   BOOKING_STATUS = 4,
// }
