import React from "react";
import { Box, Button, createTheme, Grid, Typography } from "@mui/material";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ButtonLoader from "../UI/ButtonLoader";
import {
  FaUser,
  FaUsers,
  FaPhone,
  FaEnvelope,
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaCar,
  FaMoneyBill,
} from "react-icons/fa";

import {
  AUTO_ALLOCATION,
  CURRENCY,
  EMAIL_TEMPLATE,
  HIDE_PREVIEW,
  PAYMENT_LINK,
  TERMS_AND_CONDITIONS,
  VALET_CHECKING,
} from "../../constants/Constants";
import { Screens } from "../../constants/enums";
import { capitalizeFirstLetter, formatDateDDMMYYY, getDayName, getTimeSlotName } from "../../helpers/utils";
import useApp from "../../hooks/useApp";
import CreateBookingService from "../../services/CreateBookingService";
import { setBooking } from "../../store/ducks/app";
import { useDispatch } from "react-redux";
import sendEmail from "../../services/EmailService";
import lightThemeOptions from "../../theme/lightThemeOptions";

interface Props {
  venue: any;
  phoneCode: string;
  booking: any;
  guests: any;
  eventDeposit: any;
  childDeposit: any;
  valetDeposit: number;
  isValet: boolean;
  isTablet: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

let theme = createTheme(lightThemeOptions);

const VerifyBooking: React.FC<Props> = ({
  venue,
  phoneCode,
  booking,
  guests,
  eventDeposit,
  childDeposit,
  valetDeposit,
  isValet,
  isTablet,
  setPage,
}) => {
  const PENDING_STATUS = "eba17df3-bc23-4c6e-ba49-06aa8aab769b";
  const { showError } = useApp();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const timeSlots: any[] = venue ? venue.timeSlots : [];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const calculateTotal = () => {
    const adultTotal = +booking.persons * eventDeposit;
    const childTotal = +booking.children * childDeposit;
    let total = adultTotal + childTotal + valetDeposit;
    total = parseFloat(total.toFixed(2));

    return total;
  };

  const formatDate = (dateStr: string) => {
    let date = dateStr.split("T")[0];
    const [year, month, day] = date.split("-");
    return year + "-" + month + "-" + day;
  };

  const submitBooking = async () => {
    try {
      setLoading(true);

      const email: string = booking.email
        ? booking.email.replace(/\s/g, "")
        : "";

      if (booking) {
        const createdBooking = await CreateBookingService({
          operationId: "1",
          customerName: booking.name.toLowerCase(),
          customerPhone: booking.mobile,
          email: booking.email ? booking.email.replace(/\s/g, "") : "",
          area: booking.area,
          date: formatDate(booking.date),
          accompaniedCount: booking.persons,
          childCount: booking.children,
          timeSlotID: booking.time,
          eventGuests: guests,
          depositStatus: eventDeposit && eventDeposit > 0 ? true : false,
          depositValue: eventDeposit,
          childDeposit: childDeposit,
          valetDeposit: valetDeposit,
          conceptID: venue.id,
        });

        dispatch(setBooking(createdBooking));

        if (AUTO_ALLOCATION) {
          if (createdBooking.statusID === PENDING_STATUS) {
            //Open Payment Link
            window.location.href = `${PAYMENT_LINK}${createdBooking.id}${
              HIDE_PREVIEW ? "/hide" : ""
            }`;
          } else {
            setPage(Screens.BOOKING_STATUS);
          }
        } else {
          // Send Email
          if (email !== "") {
            await sendEmail(EMAIL_TEMPLATE, email, createdBooking);
          }

          setPage(Screens.BOOKING_STATUS);
        }
      }
      setLoading(false);
    } catch (error: any) {
      showError("Failed to reserve a table, Please try again.");
    }
  };

  return (
    <>
      <Box
        sx={{
          my: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          minWidth: "100%",
          marginLeft: isTablet ? "12rem" : "2rem",
        }}
      >
        <Typography>BOOKING DETAILS:</Typography>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaUser style={styles.icon} /> Guest Name : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {capitalizeFirstLetter(booking.name)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaPhone style={styles.icon} /> Guest Mobile : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {booking.mobile}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaEnvelope style={styles.icon} /> Mail : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {booking.email}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaUsers style={styles.icon} /> Number of Guest(s) : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {booking.persons}
          </Typography>
        </Box>

        {booking.children && booking.children > 0 && (
          <Box
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Typography sx={{ color: "#737170" }}>
              <FaUsers style={styles.icon} /> Number of Children : &nbsp;
            </Typography>
            <Typography sx={{ color: theme.palette.primary.dark }}>
              {booking.children}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaCalendarAlt style={styles.icon} /> Date : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {`${getDayName(booking.date || "")} ${formatDateDDMMYYY(
              booking.date || ""
            )}`}

          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaClock style={styles.icon} /> Time : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {" "}
            {booking && booking.time
              ? getTimeSlotName(timeSlots, booking.time)
              : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170" }}>
            <FaMapMarkerAlt style={styles.icon} /> Location : &nbsp;
          </Typography>
          <Typography sx={{ color: theme.palette.primary.dark }}>
            {venue ? venue.location : ""}
          </Typography>
        </Box>

        {VALET_CHECKING && (
          <Box
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Typography sx={{ color: "#737170" }}>
              <FaCar style={styles.icon} /> Valet : &nbsp;
            </Typography>
            <Typography sx={{ color: theme.palette.primary.dark }}>
              {isValet
                ? "Valet service requested."
                : "Valet service not requested."}
            </Typography>
          </Box>
        )}

        {eventDeposit > 0 && (
          <Box
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Typography sx={{ color: "#737170" }}>
              <FaMoneyBill style={styles.icon} /> Total Value: &nbsp;
            </Typography>
            <Typography sx={{ color: theme.palette.primary.dark }}>
              {calculateTotal()}
            </Typography>
            <Typography sx={{ color: theme.palette.primary.dark }}>
              &nbsp; {CURRENCY}
            </Typography>
          </Box>
        )}
        {/* Terms and Condition */}
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Typography sx={{ color: "#737170", width: "100%" }}>
            T&Cs: &nbsp;
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleCheckboxChange(e)}
            style={{ padding: 0 }}
          />

          <Box
            sx={{
              mt: 2,
            }}
          >
            {TERMS_AND_CONDITIONS.map((line, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: "13px",
                  paddingLeft: "20px",
                }}
              >
                {line}
              </Typography>
            ))}
          </Box>
          {/* <Typography sx={{ color: lightTheme.palette.primary.dark }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="* The amount paid will be deducted from the total value"
            />
          </Typography> */}
        </Box>
      </Box>

      {/* Action Buttons */}
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: { xs: 2, sm: 6 },
        }}
      >
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={loading}
          sx={{
            mt: 2,
            width: "100px",
            borderRadius: "6px",
          }}
          onClick={() => setPage(Screens.BOOKING_DATE)}
        >
          Cancel
        </Button>
        
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, minWidth: "100px", borderRadius: "6px" }}
          color="primary"
          disableElevation
          endIcon={loading && <ButtonLoader />}
          disabled={loading || !isChecked}
          onClick={() => {
            submitBooking();
          }}
          style={{ borderRadius: "6px" }}
        >
          {AUTO_ALLOCATION ? "Pay to Confirm" : "Confirm"}
        </Button>
      </Grid>
    </>
  );
};

const styles = {
  icon: {
    marginRight: "0.5rem",
    color: theme.palette.primary.dark,
  },
};

export default VerifyBooking;
