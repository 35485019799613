import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useVenue from "../../hooks/useAllVenues";
import "./ReservationPage.css"; // Make sure to create this CSS file
import "./NewReservationPage.css"; // Make sure to create this CSS file
import ramadanLeftLogo from "../../assets/ramadan-left.png";
import ramadanRightLogo from "../../assets/ramadan-right.png";
import newLogo from "../../assets/new-logo.svg";
import footerLogo from "../../assets/footer.png";
import footerTabletLogo from "../../assets/footer-mobile.png";
import footerMobileLogo from "../../assets/footer-mobile.png";
// import footerLogo from "../../assets/footer.svg";
// import footerTabletLogo from "../../assets/footer-tablet.svg";
// import footerMobileLogo from "../../assets/footer-mobile.svg";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { Hidden } from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useParams } from "react-router-dom";
import { Check, Close, Person2, Add, Remove } from "@mui/icons-material";
import useIsMobile from "../../hooks/useIsMobile";
import VerifyBooking from "../../components/VerifyBooking/VerifyBooking";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  VALET_CHECKING,
  CURRENCY,
  ADD_GUESTS,
  OTP_VERIFICATION,
  SHOW_BANNER,
  VALET_DEPOSIT,
  NOTES,
  CHILD_CHECKING,
  SEARCH_PHONE_NUMBER,
} from "../../constants/Constants";
import BookingOTP from "../../components/BookingOTP/BookingOTP";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import useApp from "../../hooks/useApp";
import BookingStatus from "../../components/BookingStatus/BookingStatus";
import { Screens } from "../../constants/enums";
import { useSelector } from "react-redux";
import { GetUserService } from "../../services/GetUserService";
import { enableVenueDeposit } from "../../helpers/utils";
import { CSSProperties } from "@mui/styles";

const theme = createTheme(lightThemeOptions);

const afterAWeek = () => {
  try {
    // const lastDayInRamadan = new Date("2024-4-9");
    const lastDayInRamadan = new Date(2025, 4, 10);
    return lastDayInRamadan.toISOString().split("T")[0];
  } catch (error: any) {
    const today = new Date();
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return nextWeek.toISOString().split("T")[0];
  }
};

function NewReservationPage() {
  const {
    control,
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange", // Trigger validation on change
    // mode: "onTouched",
    shouldFocusError: false,
  });

  const { venue, venueLoading, venueError } = useVenue();

  const params = useParams();
  const origin = params.source || "default";
  const { web, isTablet } = useIsMobile();
  const { showError } = useApp();

  const [isValet, setIsValet] = useState(false);
  const [persons, setPersons] = useState("1"); // Initial value of persons
  const [children, setChildren] = useState("0"); // Initial value of children
  const [area, setArea] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<any>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<any>(null);
  const [mobile, setMobile] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(new Date().toISOString().split("T")[0]);
  const [deposit, setDeposit] = useState<any>(0);
  const [childDeposit, setChildDeposit] = useState<any>(0);
  const [totalPay, setTotalPay] = useState<any>(0);
  const [guest, setGuest] = useState<string>("");
  const [guests, setGuests] = useState<string[]>([]);
  const [addGuestList, setAddGuestList] = useState(false);
  const [page, setPage] = useState(Screens.BOOKING_DATE);
  const [booking, setBooking] = useState({
    area: "",
    date: "",
    email: "",
    mobile: "",
    name: "",
    persons: "",
    children: "",
    time: "",
  });
  const isDarkMode = useSelector((state: any) => state.app.darkMode);

  const [userDataLoading, setUserDataLoading] = useState(false);
  const handlePersonsDecrement = () => {
    setPersons((prev) => Math.max(1, +prev - 1).toString());
  };

  useEffect(() => {
    if (venue) {
      let availableTimeSlots = getAvailableTimeSlots();
      let defaultSlot = availableTimeSlots[0];
      setTime(defaultSlot.friendlyName + " (" + defaultSlot.name + ")");
    }
  }, [venue]);

  useEffect(() => {
    const getVenueDeposit = async () => {
      let enableDeposit: boolean = await enableVenueDeposit(
        venue,
        selectedDate
      );

      if (venue && enableDeposit) {
        setDeposit(venue.eventDeposit);
        setChildDeposit(venue.childDeposit);

        let valetDeposit = isValet ? VALET_DEPOSIT : 0;

        const adultTotal = +persons * venue.eventDeposit;
        const childTotal = +children * venue.childDeposit;
        setTotalPay(adultTotal + childTotal + valetDeposit);
      } else {
        setDeposit(0);
        setChildDeposit(0);
        setTotalPay(0);
      }
    };

    getVenueDeposit();
  }, [venue, selectedDate]);

  useEffect(() => {
    if (+persons > 0) {
      let valetDeposit = isValet ? VALET_DEPOSIT : 0;
      const adultTotal = +persons * deposit;
      const childTotal = +children * childDeposit;

      setTotalPay(adultTotal + childTotal + valetDeposit);
    } else {
      setTotalPay(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons, children, isValet]);

  useEffect(() => {
    async function getUserData(mobile: string) {
      setUserDataLoading(true);
      const users = await GetUserService(mobile);

      if (users && users.length > 0) {
        const userName = users[0].name;
        const userEmail = users[0].email;

        setValue("name", userName);
        setValue("email", userEmail);
      }
      setUserDataLoading(false);
    }

    if (SEARCH_PHONE_NUMBER) {
      const regex = /^(01[0-2]|015|05)\d{8}$/;
      const phoneNumber = getValues("mobile");

      // run function only if the mobile number is valid
      if (regex.test(phoneNumber)) {
        getUserData(phoneNumber);
      } else {
        // reset the name and email fields if the mobile number is invalid
        setValue("name", "");
        setValue("email", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("mobile")]);

  const getAvailableTimeSlots = (formattedDate?: any) => {
    let date = formattedDate
      ? formattedDate
      : dayjs(selectedDate).format("YYYY-MM-DD");
    let dayName = dayjs(date, "YYYY-MM-DD").day(); // Get day as a number (0-6) (sun, sat)
    const isBetween = dayName >= 0 && dayName <= 3;
    let availableTimeSlots;
    if (isBetween) {
      availableTimeSlots = venue.timeSlots.filter(
        (slot: any) =>
          slot.id !== "e8714ea9-8b18-4cf8-ad68-c4a134f23ff8" &&
          slot.id !== "57ebbef8-e90c-4ae9-8c3e-d172ecb11832" // 1 AM
      );
      setAvailableTimeSlots(availableTimeSlots);
    } else {
      availableTimeSlots = venue.timeSlots.filter(
        (slot: any) =>
          slot.id !== "6b6f7c57-ddcf-4390-8e28-382ac93da555" &&
          slot.id !== "29439cad-7a04-401b-9806-d81d417d0d39" // 12 AM
      );
      setAvailableTimeSlots(availableTimeSlots);
    }
    return availableTimeSlots;
  };

  const handleDateChange = (newDate: any) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD"); // Converts to "2024-03-15"
    setSelectedDate(formattedDate);
    getAvailableTimeSlots(formattedDate);
  };

  const handleNextClick = async () => {
    if (page === Screens.BOOKING_DATE) {
      setPage(Screens.BOOKING_TIME); // BOOKING_TIME
    }
    if (page === Screens.BOOKING_TIME) {
      setPage(Screens.BOOKING_GUEST_INFO);
    }
  };

  const handleBackClick = () => {
    if (page === Screens.BOOKING_GUEST_INFO) setPage(Screens.BOOKING_TIME);
    if (page === Screens.BOOKING_TIME) setPage(Screens.BOOKING_DATE);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "name") setName(value);
    if (name === "email") setEmail(value);
    if (name === "mobile") setMobile(value);
  };

  const handleGuestList = () => {
    setAddGuestList(!addGuestList);
  };
  const handleGuestChange = (event: any) => {
    setGuest(event.target.value);
  };

  const handleAddGuest = () => {
    if (guests.length >= +persons) {
      showError("You have exceeded the guest number!");

      return;
    }
    if (guest && guest.length > 0) {
      setGuests((prevGuests) => [...prevGuests, guest]);
      setGuest("");
    }
  };

  const handleRemoveGuest = (index: number) => {
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests.splice(index, 1);
      return updatedGuests;
    });
  };

  const [fieldWidth, setFieldWidth] = useState("100%");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const userFields = [
    {
      name: "name",
      label: "Full Name",
      sx: { width: { xs: "100%", sm: fieldWidth } },
      size: "medium",
      InputProps: { sx: { borderRadius: "10px" } },
      margin: "normal",
      type: "text",
      value: name,
      register: {
        ...register("name", {
          required: "Full Name is required",
        }),
      },
      // shrink: Boolean(getValues("name"))
      InputLabelProps: {},
      autoComplete: false,
      autoCompleteProps: {},
    },
    {
      name: "mobile",
      label: "Phone number",
      value: mobile,
      sx: { width: { xs: "100%", sm: fieldWidth } },
      size: "medium",
      InputProps: {
        sx: { borderRadius: "10px" },
        // endAdornment: <>{userDataLoading && <CircularProgress />}</>,
      },
      margin: "normal",
      type: "text",
      register: {
        ...register("mobile", {
          required: "Phone number is required",
          pattern: {
            // value: /^(010|011|012|015)\d{8}$/, // Egypt only
            value: /^(01[0-2]|015|05)\d{8}$/, // To accept KSA numbers also
            message: "Enter a valid phone number",
          },
        }),
      },
      InputLabelProps: {},
      autoComplete: false,
      autoCompleteProps: {},
    },
    {
      name: "email",
      label: "Email",
      value: email,
      sx: { width: { xs: "100%", sm: fieldWidth } },
      size: "medium",
      InputProps: { sx: { borderRadius: "10px" } },
      margin: "normal",
      type: "email",
      register: {
        ...register("email", {
          required: "Email is required",
          pattern: {
            value: /^\S+@\S+\.\S+$/,
            message: "Enter a valid email",
          },
        }),
      },
      // shrink: Boolean(getValues("email"))
      InputLabelProps: {},
      autoComplete: false,
      autoCompleteProps: {},
    },
  ];

  function getAutoCompleteOptions(item: any) {
    if (item.name === "area") {
      return venue.areas;
    } else if (item.name === "time") {
      return venue.timeSlots.map(
        (item: any) => item.friendlyName + " (" + item.name + ")"
      );
    } else {
      return [];
    }
  }

  const onSubmit = (data: any) => {
    const foundSlot = venue.timeSlots.find((slot: any) => {
      const dataToCompare = slot.friendlyName + " (" + slot.name + ")";
      return dataToCompare === time;
    });
    const id = foundSlot ? foundSlot.id : "";
    const updatedData = {
      ...data,
      time: id,
      isValet,
      persons: persons,
      children: children,
      date: selectedDate,
    };
    setBooking(updatedData);
    if (OTP_VERIFICATION) {
      setPage(Screens.BOOKING_OTP);
    } else {
      setPage(Screens.BOOKING_CONFIRMATION);
    }

    // Reset Form
    resetForm();
  };

  const resetForm = () => {
    reset();
    setPersons("1");
    setChildren("0");
    setArea(null);
    let defaultSlot = venue.timeSlots[0];
    setTime(defaultSlot.friendlyName + " (" + defaultSlot.name + ")");
  };

  if (venueLoading || venue === null) {
    return (
      <>
        <Hidden mdUp>
          <SplashScreen venue={venue} />
        </Hidden>

        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      </>
    );
  } else if (venueError) return <Alert severity="error">{venueError}</Alert>;
  else if (venue)
    return (
      <div
        style={
          !web
            ? {
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                width: "100%",
                minHeight: "100vh",
              }
            : {
                backgroundColor: theme.palette.background.paper,
                display: "flex",
                // flexDirection: "row",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                width: "100%",
                minHeight: "100vh",
                overflow: "hidden",
                // msOverflowStyle: 'none', // IE and Edge
                // scrollbarWidth: "none",
              }
        }
      >
        <Grid
          container
          width={"100%"}
          style={{ justifyContent: "center" }}
          spacing={!web ? 0 : 2}
        >
          {/* !web  && (
            // <Grid item xs={12} md={4}>
            <Grid item xs={0} md={0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={require("../../assets/new-splash-screen.png")}
                  // src={
                  //   venue.image
                  //     ? venue.image
                  //     : require("../../assets/new-splash-screen.png")
                  // }
                  alt="Venue"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
              </Box>
            </Grid>
          )} */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {web || isTablet ? (
              <img
                src={ramadanLeftLogo}
                alt="Ramadan"
                style={{ width: "70%", height: "auto" }}
              />
            ) : (
              <img
                src={ramadanLeftLogo}
                alt="Ramadan"
                style={{ width: "50%", height: "auto" }}
              />
            )}
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {web || isTablet ? (
              <img
                src={ramadanRightLogo}
                alt="Ramadan"
                style={{ width: "70%", height: "auto" }}
              />
            ) : (
              <img
                src={ramadanRightLogo}
                alt="Ramadan"
                style={{ width: "50%", height: "auto" }}
              />
            )}
          </div>

          <Grid
            item
            xs={12}
            md={8}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            {/* Concept Logo */}
            {venue && venue.logo && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: web ? "27%" : isTablet ? "30%" : "50%",
                  marginTop: web || isTablet ? "5rem" : "7rem",
                }}
              >
                <img
                  src={newLogo} // CHANGE: need to use venue logo from DB
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: web || isTablet ? "65%" : "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {page !== Screens.BOOKING_STATUS &&
                page !== Screens.BOOKING_CONFIRMATION && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ ...styles.TitleText }}
                    >
                      Reservation
                    </Typography>
                    {page === Screens.BOOKING_DATE && (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ ...styles.DescriptionText }}
                      >
                        Choose your reservation date
                      </Typography>
                    )}
                    {page === Screens.BOOKING_TIME && (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ ...styles.DescriptionText }}
                      >
                        Pick your prefered time slot
                      </Typography>
                    )}
                    {page === Screens.BOOKING_GUEST_INFO && (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ ...styles.DescriptionText }}
                      >
                        Enter the information below
                      </Typography>
                    )}
                  </div>
                )}
            </div>

            {/* Booking Form */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: page === Screens.BOOKING_STATUS ? "100%" : "100%",
                // width: page === Screens.BOOKING_STATUS ? "100%" : web ? "70%" : isTablet ? "50%" : "70%",
              }}
            >
              {/* Date Selector */}
              {page === Screens.BOOKING_DATE && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      defaultValue={dayjs(selectedDate)}
                      onChange={(newDate) => {
                        handleDateChange(newDate);
                      }}
                      minDate={dayjs()} // Today
                      maxDate={dayjs().add(7, "day")}
                      shouldDisableDate={(date) => date.date() > 30} // Disables dates beyond the 31st
                      slotProps={{
                        actionBar: { actions: [] }, // Removes OK & Cancel buttons
                        toolbar: { sx: { display: "none" } },
                      }}
                      sx={{
                        // Reduce the overall size of the calendar
                        "& .MuiPickersLayout-contentWrapper": {
                          height: "315px",
                        },
                        // Reduce the overall size of the calendar
                        "& .MuiPickersCalendarHeader-root": {
                          border: "1px solid black",
                          borderTopRightRadius: "1rem",
                          borderTopLeftRadius: "1rem",
                          borderBottom: "none",
                          marginBottom: "0",
                          paddingLeft: "15px",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                        "& .MuiDayCalendar-header": {
                          border: "1px solid black",
                          borderBottom: "none",
                          borderTop: "none",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                        "& .MuiDayCalendar-slideTransition": {
                          // height: "210px !important",
                          border: "1px solid black",
                          borderBottomRightRadius: "1rem",
                          borderBottomLeftRadius: "1rem",
                        },
                        "& .MuiDayCalendar-header, & .MuiPickersSlideTransition-root":
                          {
                            margin: "0 auto",
                          },
                        "& .MuiDayCalendar-weekContainer": {
                          // fontSize: "0.875rem", // Reduce font size of weekdays and header
                          color: "#000",
                          fontWeight: "bold",
                          gap: "0.4rem",
                          marginTop: "7px",
                          marginBottom: "7px",
                          display: "flex",
                          justifyContent: "space-around",
                        },
                        "& .MuiTypography-root": {
                          fontSize: "0.6rem", // Reduce font size of weekdays and header
                          height: "20px",
                          color: "black",
                        },
                        "& .MuiDateCalendar-root": {},
                        "& .MuiPickersDay-root": {
                          width: "30px", // Reduce the size of each day cell
                          height: "30px",
                          fontSize: "0.6rem", // Reduce font size of days
                          ////////////////////
                          color: "#000",
                          backgroundColor: "transparent",
                          borderRadius: "50%",
                          "&:hover": {
                            backgroundColor: "#000",
                            color: "#fff",
                          },
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#000 !important",
                          color: "#fff !important",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // width: "100%",
                      width: "320px",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleBackClick}
                      sx={{
                        mt: 2,
                        width: "100px",
                        borderRadius: "6px",
                        color: "#000",
                        backgroundColor: "#E2E1E0",
                        "&:hover": {
                          color: "#000",
                          backgroundColor: "#E2E1E0",
                        },
                      }}
                    >
                      {"Back"}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleNextClick}
                      sx={{ mt: 2, width: "100px", borderRadius: "6px" }}
                    >
                      {"Next"}
                    </Button>
                  </div>
                </>
              )}

              {/* Time Selector */}
              {page === Screens.BOOKING_TIME && (
                <form
                  id="timeForm"
                  // onSubmit={handleSubmit(onSubmit)}
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <>
                    <FormControl
                      component="fieldset"
                      className="time-slot-container"
                    >
                      <RadioGroup
                        value={time}
                        onChange={(e) => {
                          setTime(e.target.value); // Stores the selected ID
                        }}
                        style={{ width: "100%" }}
                      >
                        {availableTimeSlots.length &&
                          availableTimeSlots.map((slot: any) => (
                            <div
                              key={slot.id}
                              className={`slot-item ${
                                time === slot.id ? "selected" : ""
                              }`}
                            >
                              <FormControlLabel
                                label={
                                  slot.friendlyName + " (" + slot.name + ")"
                                }
                                value={
                                  slot.friendlyName + " (" + slot.name + ")"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 20, // Adjust size
                                        borderRadius: "50%", // Keep circular shape
                                        border: "1px solid black",
                                        color: "transparent",
                                      },
                                      "&.Mui-checked .MuiSvgIcon-root": {
                                        backgroundColor: "black", // Ensure solid black when selected
                                        border: "1px solid black",
                                      },
                                    }}
                                  />
                                }
                              />
                            </div>
                          ))}
                      </RadioGroup>
                    </FormControl>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleBackClick}
                        sx={{
                          mt: 2,
                          width: "100px",
                          borderRadius: "6px",
                          color: "#000",
                          backgroundColor: "#E2E1E0",
                          "&:hover": {
                            color: "#000",
                            backgroundColor: "#E2E1E0",
                          },
                        }}
                      >
                        {"Back"}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleNextClick}
                        sx={{ mt: 2, width: "100px", borderRadius: "6px" }}
                      >
                        {"Next"}
                      </Button>
                    </div>
                  </>
                </form>
              )}

              <form
                id="bookingForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  width: "80%",
                  // width: web || isTablet ? "100%" : "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* Booking Guest Info */}
                {page === Screens.BOOKING_GUEST_INFO && (
                  <div>
                    {/* Adults Counter */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <button
                        type="button"
                        color="primary"
                        style={{
                          ...styles.counterButton,
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setPersons((prev) =>
                            Math.max(1, +prev - 1).toString()
                          )
                        }
                      >
                        <strong>-</strong>
                      </button>
                      <TextField
                        key={"11"}
                        label={`Number Of Adults`}
                        value={persons}
                        sx={{
                          width: fieldWidth,
                          borderRadius: "10px",
                        }}
                        size="medium"
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        type="number"
                        margin="normal"
                        onChange={(e) => {
                          const value = Math.max(1, Number(e.target.value)); // Prevent values below 1
                          setPersons(value.toString());
                          // setPersons(e.target.value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "e")
                            e.preventDefault();
                        }}
                        onWheel={(e) => {
                          //@ts-ignore
                          e.target?.blur();
                        }}
                      />
                      <button
                        type="button"
                        color="primary"
                        style={{
                          ...styles.counterButton,
                          marginLeft: "20px",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          setPersons((prev) => (+prev + 1).toString())
                        }
                      >
                        <strong>+</strong>
                      </button>

                      {/* <TextField
                        key={"11"}
                        // name="adults"
                        label={`Number of Adults`}
                        value={persons}
                        sx={{
                          width: { xs: "100%", sm: fieldWidth },
                          // width: { xs: "18rem", sm: fieldWidth },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#000" }, // Default border color
                            "&:hover fieldset": { borderColor: "#000" }, // Hover border color
                            "&.Mui-focused fieldset": { borderColor: "#000" }, // Focus border color
                          },
                        }}
                        size="medium"
                        InputProps={{
                          sx: { borderRadius: "10px" },
                          inputProps: { min: 1 }, // Set minimum value to 1
                        }}
                        type="number"
                        margin="normal"
                        onChange={(e) => setPersons(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "e")
                            e.preventDefault();
                        }}
                        onWheel={(e) => {
                          //@ts-ignore
                          e.target?.blur();
                        }}
                      /> */}

                      {/* <TextField
                        key={"11"}
                        label="Number of Adults"
                        value={persons}
                        sx={{
                          width: { xs: "100%", sm: fieldWidth },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#000" }, // Default border color
                            "&:hover fieldset": { borderColor: "#000" }, // Hover border color
                            "&.Mui-focused fieldset": { borderColor: "#000" }, // Focus border color
                          },
                        }}
                        size="medium"
                        InputProps={{
                          readOnly: true, // Prevent direct text input
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                // onClick={() => setPersons((prev) => prev - 1)}
                                onClick={() => setPersons((prev) => (Math.max(1, +prev - 1)).toString())}
                              >
                                <Remove />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setPersons((prev) => (+prev + 1).toString())}>
                                <Add />
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: { 
                            borderRadius: "10px",
                            display: "flex", 
                            alignItems: "center", 
                            justifyContent: "center" 
                          },
                          inputProps: {
                            style: { textAlign: "center" }, // Centers the text
                          },
                          // inputProps: { min: 1, type: "number" }, // Set minimum value to 1
                        }}
                        margin="normal"
                        onChange={(e)=>{
                          const value = Math.max(1, Number(e.target.value)); // Prevent values below 1
                          setPersons(value.toString());
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "e") e.preventDefault();
                        }}
                        onWheel={(e) => {
                          //@ts-ignore
                          e.target?.blur();
                        }}
                      /> */}
                    </div>

                    {ADD_GUESTS && +persons > 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={handleGuestList}>
                          {addGuestList
                            ? "Remove Guest List"
                            : " Add Guest List"}
                        </Button>

                        {addGuestList &&
                          page === Screens.BOOKING_GUEST_INFO && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                width: web ? "50%" : "70%",
                              }}
                            >
                              <TextField
                                label="Add Guest"
                                size="small"
                                value={guest}
                                onChange={handleGuestChange}
                                margin="normal"
                                type="default"
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddGuest();
                                  }
                                }}
                                InputProps={{
                                  sx: { borderRadius: "10px" },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Check
                                        onClick={handleAddGuest}
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                                style={{
                                  width: web ? "80%" : "100%",
                                }}
                              />

                              <List
                                dense={true}
                                style={{
                                  maxHeight: "200px",
                                  overflowY:
                                    guests.length > 4 ? "scroll" : "hidden",
                                  width: web ? "80%" : "100%",
                                }}
                                className={guests.length > 4 ? "scroll" : ""}
                              >
                                {guests.map((value, index) => (
                                  <ListItem
                                    secondaryAction={
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                      >
                                        <Close
                                          onClick={() =>
                                            handleRemoveGuest(index)
                                          }
                                        />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        <Person2 />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={value} />
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          )}
                      </div>
                    )}

                    {/* Children Section */}
                    {CHILD_CHECKING && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <button
                          type="button"
                          color="primary"
                          style={{
                            ...styles.counterButton,
                            marginRight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setChildren((prev) =>
                              Math.max(0, +prev - 1).toString()
                            )
                          } // Prevent values below 0
                        >
                          <strong>-</strong>
                        </button>
                        <TextField
                          key={"12"}
                          label={`Number Of Children`}
                          value={children}
                          sx={{
                            width: fieldWidth,
                            borderRadius: "10px",
                          }}
                          size="medium"
                          InputProps={{
                            sx: {
                              borderRadius: "10px",
                            },
                          }}
                          type="number"
                          margin="normal"
                          onChange={(e) => {
                            const value = Math.max(0, Number(e.target.value)); // Prevent values below 0
                            setChildren(value.toString());
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "." || e.key === "e")
                              e.preventDefault();
                          }}
                          onWheel={(e) => {
                            //@ts-ignore
                            e.target?.blur();
                          }}
                        />
                        <button
                          type="button"
                          color="primary"
                          style={{
                            ...styles.counterButton,
                            marginLeft: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            setChildren((prev) => (+prev + 1).toString())
                          }
                        >
                          <strong>+</strong>
                        </button>
                      </div>
                    )}

                    {!isIOS &&
                      userFields.map((item: any, index: number) => (
                        <TextField
                          defaultValue={item.value}
                          key={index}
                          label={item.label}
                          sx={item.sx}
                          size={item.size}
                          InputProps={item.InputProps}
                          type={item.type}
                          margin={item.margin}
                          inputMode="numeric"
                          // pattern="[0-9]*"
                          {...item.register}
                          disabled={userDataLoading}
                          onKeyDown={(e) => {
                            if (item.name !== "mobile") {
                              return;
                            }
                            // prevent text
                            if (
                              !/[\d\b]/.test(e.key) &&
                              ![
                                "Backspace",
                                "Delete",
                                "ArrowLeft",
                                "ArrowRight",
                                "Tab",
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            if (item.type === "number") {
                              //@ts-ignore
                              e.target?.blur();
                            }
                          }}
                          error={!!errors[item.name]}
                          helperText={errors[item.name]?.message?.toString()}
                          InputLabelProps={item.InputLabelProps}
                          onBlur={() => trigger(item.name)}
                        />
                      ))}

                    {isIOS &&
                      userFields.map((item: any, index: number) => (
                        <TextField
                          key={index}
                          label={item.label}
                          sx={item.sx}
                          size={item.size}
                          InputProps={item.InputProps}
                          type={item.type}
                          margin={item.margin}
                          {...item.register}
                          onKeyDown={(e) => {
                            if (item.name !== "mobile") {
                              return;
                            }
                            // prevent text
                            if (
                              !/[\d\b]/.test(e.key) &&
                              ![
                                "Backspace",
                                "Delete",
                                "ArrowLeft",
                                "ArrowRight",
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          error={!!errors[item.name]}
                          helperText={errors[item.name]?.message?.toString()}
                          InputLabelProps={item.InputLabelProps}
                        />
                      ))}

                    {/* Valet */}
                    {VALET_CHECKING && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={isValet}
                          onChange={(e) => setIsValet(e.target.checked)}
                        />
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Requires Valet
                        </Typography>
                        <Tooltip
                          title={`This service will add flat ${VALET_DEPOSIT} ${CURRENCY} to your booking total amount`}
                          placement="right-start"
                        >
                          <IconButton>
                            <InfoOutlinedIcon
                              sx={{
                                color: theme.palette.primary.dark,
                                fontSize: "20px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}

                    {/* Total Amount Calculator */}
                    {deposit > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography sx={{ color: "#737170" }}>
                          Total amount : &nbsp;
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.dark }}>
                          {parseFloat(totalPay.toFixed(2))} {CURRENCY}
                        </Typography>
                      </Box>
                    )}

                    {/* List reservation notes */}
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      {NOTES.map((note, index) => (
                        <Typography
                          key={index}
                          sx={{
                            fontSize: "13px",
                            paddingLeft: "20px",
                          }}
                        >
                          {note}
                        </Typography>
                      ))}
                    </Box>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleBackClick}
                        sx={{
                          mt: 2,
                          width: "100px",
                          borderRadius: "6px",
                          color: "#000",
                          backgroundColor: "#E2E1E0",
                          "&:hover": {
                            color: "#000",
                            backgroundColor: "#E2E1E0",
                          },
                        }}
                      >
                        {"Back"}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, width: "100px", borderRadius: "6px" }}
                      >
                        {"Next"}
                      </Button>
                    </div>
                  </div>
                )}

                {OTP_VERIFICATION && page === Screens.BOOKING_OTP && (
                  <BookingOTP
                    phoneCode={"+20"}
                    booking={booking}
                    guests={guests}
                    eventDeposit={deposit}
                    conceptID={venue.id}
                    setPage={setPage}
                  ></BookingOTP>
                )}

                {page === Screens.BOOKING_CONFIRMATION && (
                  <VerifyBooking
                    venue={venue}
                    phoneCode={"+20"}
                    booking={booking}
                    guests={guests}
                    eventDeposit={deposit}
                    childDeposit={childDeposit}
                    valetDeposit={isValet ? VALET_DEPOSIT : 0}
                    isValet={isValet}
                    setPage={setPage}
                    isTablet={isTablet}
                  ></VerifyBooking>
                )}

                {page === Screens.BOOKING_STATUS && (
                  <BookingStatus
                    venue={venue}
                    guestEmail={booking.email}
                    setPage={setPage}
                    web={web}
                    isTablet={isTablet}
                  ></BookingStatus>
                )}

                {/* Action Buttons */}
                {(page === Screens.BOOKING_GUEST_INFO ||
                  page === Screens.BOOKING_DATE ||
                  page === Screens.BOOKING_TIME) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width:
                        page === Screens.BOOKING_DATE && (web || isTablet)
                          ? "320px"
                          : "100%",
                    }}
                  ></div>
                )}
              </form>
            </div>

            {/* {SHOW_BANNER && <Banner></Banner>} */}
          </Grid>
        </Grid>

        {/* Banner CHANGE: use show banner condition */}
        {/* Footer */}
        <Box>
          {web ? (
            <img
              style={{
                height: "100%",
                width: "100%",
                marginTop: "2rem",
                display: "block",
              }}
              src={footerLogo}
              alt="Footer"
            />
          ) : isTablet ? (
            <img
              style={{
                height: "100%",
                width: "100%",
                marginTop: "2rem",
                display: "block",
              }}
              src={footerTabletLogo}
              alt="Footer"
            />
          ) : (
            <img
              style={{
                width: "100%",
                display: "block",
                marginTop: "2rem",
              }}
              src={footerMobileLogo}
              alt="Footer"
            />
          )}
        </Box>
      </div>
    );
  else return <div></div>;
}

const styles: Record<string, CSSProperties> = {
  counterButton: {
    borderRadius: "10%",
    backgroundColor: theme.palette.primary.dark,
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    color: "white",
    zIndex: 99,
  },
  TitleText: {
    textAlign: "center",
    fontFamily: "Arial",
    marginTop: "15px",
    fontSize: "2.3rem",
    fontWeight: 700,
    marginBottom: "0",
    flex: 1, // Allow title to take up remaining space
  },
  DescriptionText: {
    textAlign: "center",
    fontFamily: "Arial",
    fontSize: "1.2rem",
    fontWeight: 700,
    marginTop: "0.8rem",
    flex: 1, // Allow title to take up remaining space
  },
  TimeSlotContainer: {
    display: "flex",
  },
};

export default NewReservationPage;
