import React from "react";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";

import {
  Avatar,
  Box,
  Button,
  Typography,
  Grid,
  createTheme,
} from "@mui/material";

import { Screens } from "../../constants/enums";
import { useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  formatDateDDMMYYY,
  getDayName,
  getTimeSlotName,
} from "../../helpers/utils";
import Separator from "../Separator/Separator";
import { VALET_CHECKING } from "../../constants/Constants";
import lightThemeOptions from "../../theme/lightThemeOptions";

interface Props {
  venue: any;
  guestEmail: string;
  setPage: (page: number) => void;
  web: boolean;
  isTablet: boolean;
}

const theme = createTheme(lightThemeOptions);

const BookingStatus: React.FC<Props> = ({
  venue,
  guestEmail,
  setPage,
  web,
  isTablet,
}) => {
  const timeSlots: any[] = venue ? venue.timeSlots : [];
  const booking: any = useSelector((state: any) => state.app.booking);
  // console.log({booking})

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Status Section */}
          {/* <Grid item xs={12} justifyContent="center" alignItems="center">
            <Avatar
              sx={{
                width: 60,
                height: 60,
                backgroundColor: "#ffdac4", // Set your custom background color
                // border: "2px solid #986840", // Set your border color
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                marginX: "auto", // Center horizontally
              }}
            >
              <UpdateOutlinedIcon fontSize="large" sx={{ color: "black" }} />
            </Avatar>

            <Typography component="p" gutterBottom sx={styles.message}>
              THANK YOU FOR YOUR RESERVATION!
            </Typography>
            <Typography component="p" sx={styles.description}>
              Our Team is finding you a table,
            </Typography>
            <Typography component="p" sx={styles.description}>
              you will receive an SMS to confirm
            </Typography>

            <Separator />
          </Grid> */}

          {/* Booking Details */}
          {/* <Grid item xs={12}>
            <Typography>BOOKING DETAILS:</Typography>
            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Guest Name : &nbsp;</Typography>
              <Typography component="p" gutterBottom sx={styles.details}>
                {booking && booking.customerName
                  ? capitalizeFirstLetter(booking.customerName)
                  : ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Guest Mobile : &nbsp;</Typography>
              <Typography component="p" gutterBottom sx={styles.details}>
                {booking ? booking.customerPhone : ""}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Mail : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {guestEmail}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>
                Number of Guest(s) : &nbsp;
              </Typography>
              <Typography component="p" sx={styles.details}>
                {booking ? booking.accompaniedCount : "1"}
              </Typography>
            </Box>

            {booking.childCount && booking.childCount > 0 && (
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                }}
              >
                <Typography sx={styles.title}>
                  Number of Children : &nbsp;
                </Typography>
                <Typography component="p" sx={styles.details}>
                  {booking ? booking.childCount : "1"}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Date : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {`${getDayName(booking.date || "")} ${formatDateDDMMYYY(
                  booking.date || ""
                )}`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
              <Typography sx={styles.title}>Time : &nbsp;</Typography>
              <Typography component="p" sx={styles.details}>
                {booking && booking.timeSlots && booking.timeSlots.length > 0
                  ? getTimeSlotName(
                      timeSlots,
                      booking.timeSlots[0] ? booking.timeSlots[0] : ""
                    )
                  : ""}
              </Typography>
            </Box>

            {VALET_CHECKING && (
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                }}
              >
                <Typography sx={styles.title}>Valet : &nbsp;</Typography>
                <Typography component="p" sx={styles.details}>
                  {booking && booking.valetDeposit > 0
                    ? "Valet service requested."
                    : "Valet service not requested."}
                </Typography>
              </Box>
            )}
          </Grid> */}
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            <Typography variant="h3" gutterBottom sx={{ ...styles.TitleText }}>
              Your reservation
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ ...styles.TitleText, marginTop: "0px" }}
            >
              is pending
            </Typography>
          </div>
          <div>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...styles.DescriptionText }}
            >
              An email will be sent to you
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ ...styles.DescriptionText }}
            >
              to confirm your reservation
            </Typography>
          </div>
        </div>
        {/* Actions Section */}
        <Button
          variant="contained"
          sx={{
            width: web ? "100px" : isTablet ? "200px" : "120%",
            // color: "#000",
            color: "white",
            paddingTop: web ? "auto" : "13px",
            paddingBottom: web ? "auto" : "13px",
            marginTop: 5,
            cursor: "pointer",
            borderRadius: "6px",
          }}
          onClick={(page) => setPage(Screens.BOOKING_DATE)}
        >
          Done
        </Button>
      </Box>
    </>
  );
};

const styles = {
  TitleText: {
    textAlign: "center",
    fontFamily: "Arial",
    // marginTop: "1rem",
    fontSize: "2.1rem",
    // width: "3rem",
    fontWeight: 700,
    marginBottom: "0",
    flex: 1, // Allow title to take up remaining space
  },
  title: {
    color: "#737170",
  },
  // details: {
  //   color: theme.palette.primary.dark,
  // },
  DescriptionText: {
    textAlign: "center",
    fontFamily: "Arial",
    fontSize: "1.1rem",
    fontWeight: 700,
    margin: "0",
    // marginTop: "0.8rem",
    flex: 1, // Allow title to take up remaining space
  },
  // message: {
  //   color: "#black",
  //   textAlign: "center",
  //   fontSize: "16px",
  //   letterSpacing: "0.64px",
  //   textTransform: "uppercase",
  // },
  // description: {
  //   color: theme.palette.primary.dark,
  //   textAlign: "center",
  //   fontSize: "14px",
  //   letterSpacing: "0.56px",
  //   textTransform: "uppercase",
  // },
};
export default BookingStatus;
