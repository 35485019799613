import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    // Egypt
    primary: {
      light: "#FF853E",
      main: "#000",
      dark: "#000",
      contrastText: "#fff",
      // main: "#F9650E",
      // dark: "#F9650E",
    },
    // KSA
    // primary: {
    //   light: "#73ad73",
    //   main: "#509950",
    //   dark: "#509950",
    //   contrastText: "#fff",
    // },
    secondary: {
      light: "#FFFBF8",
      main: "#FFFBF8",
      dark: "#FFFBF8",
      contrastText: "#fff",
    },
    background: {
      // main: "#FFFBF8",
      // paper: "#FFFBF8",
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
